import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  MsalProvider,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "./authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";
import Home from "./Home";
import Paps from "./Paps";
import BrokerList from "./BrokerList";
import EditPod from "./EditPod";
import Pars from "./Pars";
import Edit from "./Edit";
import Chat from "./Chat";
import PodHistory from "./PodHistory";
import Pod from "./Pod";
import "./App.css";
import Navbar from "./Navbar";
import PdfEditor from "./PdfEditor";
import MainPage from "./MainPage";
import LoadQuote from "./LoadQuote";
import DocumentGeneration from "./DocumentGeneration";
import EmailGeneration from "./EmailGeneration";
import OrderHistory from "./OrderHistory";
import EditOrder from "./EditOrder";
import Orders from "./Orders";

const AppProvider = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [parsCode, setParsCode] = useState(0);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [phone, setPhone] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [papsCode, setPapsCode] = useState(0);
  const [customsEmail, setCustomsEmail] = useState(null);

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    setIsAuthenticated(accounts.length > 0);
    if (accounts && accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
      const account = instance.getActiveAccount();
      if (account && account.idToken) {
        const idTokenClaims = jwtDecode(account.idToken);
        setUserId(idTokenClaims.sub);
      }
    }

    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        instance.setActiveAccount(event.payload.account);
        setIsAuthenticated(true);
        const idTokenClaims = jwtDecode(event.payload.account.idToken);
        console.log("idTokenClaims:", idTokenClaims);
        setUserId(idTokenClaims.sub);
        setCompanyName(idTokenClaims.extension_CompanyName);
        setParsCode(idTokenClaims.extension_PARSCarrierCode);
        setPapsCode(idTokenClaims.extension_PAPSCarrierCode);
        setFirstName(idTokenClaims.given_name);
        setLastName(idTokenClaims.family_name);
        setAddress(idTokenClaims.streetAddress);
        setCity(idTokenClaims.city);
        setState(idTokenClaims.state);
        setZip(idTokenClaims.postalCode);
        setPhone(idTokenClaims.extension_PhoneNumber);
        setCustomsEmail(idTokenClaims.extension_CustomsEmail);

        if (
          userId &&
          userId !== "undefined" &&
          userId !== null &&
          userId !== ""
        ) {
          sessionStorage.setItem("storageUserId", userId);
          sessionStorage.setItem("storageCompanyName", companyName);
          sessionStorage.setItem("storageParsStart", parsCode);
          sessionStorage.setItem("storagePapsStart", papsCode);
          sessionStorage.setItem("storageCustomsEmail", customsEmail);
          sessionStorage.setItem("storageFirstName", firstName);
          sessionStorage.setItem("storageLastName", lastName);
          sessionStorage.setItem("storageAddress", address);
          sessionStorage.setItem("storageCity", city);
          sessionStorage.setItem("storageState", state);
          sessionStorage.setItem("storageZip", zip);
          sessionStorage.setItem("storagePhone", phone);
          insertCompanyInfo(
            userId,
            companyName,
            parsCode,
            papsCode,
            customsEmail,
            firstName,
            lastName,
            address,
            city,
            state,
            zip,
            phone
          );
          sendUserEmailToBackend(customsEmail);
        }
      } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        setIsAuthenticated(false);
        setUserId(null);
      }
    });

    return () => instance.removeEventCallback(callbackId);
  }, [
    instance,
    userId,
    companyName,
    papsCode,
    parsCode,
    customsEmail,
    phone,
    address,
    city,
    state,
    zip,
    firstName,
    lastName,
  ]);

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch(console.error);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    if (!userId && instance) {
      instance
        .acquireTokenSilent({
          scopes: ["user.read"],
          account: instance.getActiveAccount(),
        })
        .then((response) => {
          const idTokenClaims = jwtDecode(response.idToken);
          setUserId(idTokenClaims.sub);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect({ scopes: ["user.read"] });
          }
        });
    }
  }, [instance, userId]);

  const insertCompanyInfo = async (
    userId,
    companyName,
    parsCode,
    papsCode,
    customsEmail,
    firstName,
    lastName,
    address,
    city,
    state,
    zip,
    phone
  ) => {
    const endpoint = "/api/insert-company-info";
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          companyName: companyName,
          parsCode: parsCode,
          papsCode: papsCode,
          customsEmail: customsEmail,
          phone: phone,
          address: address,
          city: city,
          state: state,
          zip: zip,
          firstName: firstName,
          lastName: lastName,
        }),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error inserting company info:", error);
    }
  };

  function sendUserEmailToBackend(userEmail) {
    const url = "/api/store-user-email";

    const data = {
      userEmail: userEmail,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
  }

  return (
    <>
      <AuthenticatedTemplate>
        {React.cloneElement(children, { userId })}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Authenticating...</div>
      </UnauthenticatedTemplate>
    </>
  );
};

function App({ instance }) {
  const { inProgress } = useMsal();
  return (
    <MsalProvider instance={instance}>
      <AppProvider>
        <Router>
          <Navbar
            b2cPolicies={b2cPolicies}
            instance={instance}
            inProgress={inProgress}
          />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/broker-list" element={<BrokerList />} />
            <Route path="/customs" element={<Home />} />
            <Route path="/paps" element={<Paps />} />
            <Route path="/pars" element={<Pars />} />
            <Route path="/edit" element={<Edit />} />
            <Route path="/edit-pod" element={<EditPod />} />
            <Route path="/pod" element={<Pod />} />
            <Route path="/pod-history" element={<PodHistory />} />
            <Route path="/edit-pdf" element={<PdfEditor />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/mainPage" element={<MainPage />} />
            <Route path="/load-quote" element={<LoadQuote />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/edit-order" element={<EditOrder />} />
            <Route
              path="/document-generation"
              element={<DocumentGeneration />}
            />
            <Route path="/email-generation" element={<EmailGeneration />} />
            <Route path="/orders" element={<Orders />} />
            {/* <Route path="/invoicing" element={<Invoicing />} />
            <Route path="/customs" element={<Customs />} /> */}
          </Routes>
        </Router>
      </AppProvider>
    </MsalProvider>
  );
}

export default App;
