import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Toolbar,
  Card,
  Box,
} from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { useNavigate } from "react-router-dom";
import "./EditOrder.css";

function EditOrder() {
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadNumber = sessionStorage.getItem("selectedLoadNumber");
    if (!loadNumber) {
      navigate("/order-history");
      return;
    }

    const fetchLoadData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/get-load?loadNumber=${encodeURIComponent(loadNumber)}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // Assuming the backend response has a 'result' key
        setEditData(data.result); // Use result here
      } catch (error) {
        console.error("Failed to fetch load data:", error);
        alert(`Error fetching load data: ${error.message}`);
        navigate("/order-history");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoadData();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const dataToSend = { ...editData };
      delete dataToSend.PDF;
      delete dataToSend.UserId;

      const response = await fetch("/api/update-load", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const updatedData = await response.json();
      console.log("Order updated:", updatedData);
      alert("Order updated successfully!");
      navigate("/order-history");
    } catch (error) {
      console.error("Failed to update order:", error);
      alert(`Error updating order: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || !editData) {
    return (
      <div className="edit-order-container">
        <SideNavBar />
        <div className="edit-order-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="edit-order-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Box
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Spinner />
              </Box>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="edit-order-container">
      <SideNavBar />
      <div className="edit-order-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div className="edit-order-body">
          <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
            <Typography
              variant="h4"
              component="div"
              sx={{
                m: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Edit Order
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <form
                onSubmit={handleSubmit}
                style={{ width: "80%", margin: "auto" }}
              >
                <TextField
                  label="DateAdded"
                  name="DateAdded"
                  type="date"
                  value={editData.DateAdded || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  label="Load#"
                  name="Load#"
                  value={editData["Load#"] || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />

                <TextField
                  label="Order#"
                  name="Order#"
                  value={editData["Order#"] || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="PickupDate"
                  name="PickupDate"
                  type="date"
                  value={editData.PickupDate || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  label="Pickup"
                  name="Pickup"
                  value={editData.Pickup || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Delivery"
                  name="Delivery"
                  value={editData.Delivery || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="DeliveryDate"
                  name="DeliveryDate"
                  type="date"
                  value={editData.DeliveryDate || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  label="Rate"
                  name="Rate"
                  value={editData.Rate || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="PriceKept"
                  name="PriceKept"
                  value={editData.PriceKept || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="TripType"
                  name="TripType"
                  value={editData.TripType || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="CarrierEquipment"
                  name="CarrierEquipment"
                  value={editData.CarrierEquipment || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Status"
                  name="Status"
                  value={editData.Status || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                {/* <TextField
                  label="PDF"
                  name="PDF"
                  value={editData.PDF || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                /> */}

                <TextField
                  label="Currency"
                  name="Currency"
                  value={editData.Currency || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                {/* <TextField
                  label="UserId"
                  name="UserId"
                  value={editData.UserId || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                /> */}

                <TextField
                  label="Company"
                  name="Company"
                  value={editData.Company || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner /> : "Update Order"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/order-history")}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Box>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default EditOrder;
