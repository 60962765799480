import React, { useState, useEffect } from "react";
import { PDFDocument, StandardFonts } from "pdf-lib";
import Modal from "react-modal";
import bwipjs from "bwip-js";
import { TextField, Button } from "@mui/material";
import PdfEditor from "./PdfEditor";
import SideNavBar from "./SideNavBar";
import ConfirmationModal from "./ConfirmationModal";
import Spinner from "./Spinner";
import "./Home.css";
import { Toolbar } from "@mui/material";

function App() {
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [border, setBorder] = useState("");
  const [matchedBorder, setMatchedBorder] = useState("");
  const [storageUserId, setStorageUserId] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userCustomsEmail, setUserCustomsEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userZip, setUserZip] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadCompany, setLoadCompany] = useState("");
  const [customsBroker, setCustomsBroker] = useState("");
  const [isPdfEditorOpen, setIsPdfEditorOpen] = useState(false);
  const [combinedPdfBlob, setCombinedPdfBlob] = useState(null);
  const [toEmail, setToEmail] = useState("");
  const [brokerPhone, setBrokerPhone] = useState("");
  const [prefix, setPrefix] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [filteredBorders, setFilteredBorders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let dateString2 = "";

  useEffect(() => {
    if (prefix === "PAPS") fetchPapsNumberForPrefix();
    else if (prefix === "PARS") fetchParsNumberForPrefix();
  }, [prefix]);

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("storageUserId");
    const storedCompanyName = sessionStorage.getItem("storageCompanyName");
    const storedCustomsEmail = sessionStorage.getItem("storageCustomsEmail");
    const storedFirstName = sessionStorage.getItem("storageFirstName");
    const storedLastName = sessionStorage.getItem("storageLastName");
    const storedPhone = sessionStorage.getItem("storagePhone");
    const storedAddress = sessionStorage.getItem("storageAddress");
    const storedCity = sessionStorage.getItem("storageCity");
    const storedState = sessionStorage.getItem("storageState");
    const storedZip = sessionStorage.getItem("storageZip");
    if (storedUserId) {
      setStorageUserId(storedUserId);
      console.log("User ID:", storedUserId);
    }
    if (storedCompanyName) {
      setUserCompanyName(storedCompanyName);
    }
    if (storedCustomsEmail) {
      setUserCustomsEmail(storedCustomsEmail);
    }
    if (storedFirstName) {
      setUserFirstName(storedFirstName);
    }
    if (storedLastName) {
      setUserLastName(storedLastName);
    }
    if (storedPhone) {
      setUserPhone(storedPhone);
    }
    if (storedAddress) {
      setUserAddress(storedAddress);
    }
    if (storedCity) {
      setUserCity(storedCity);
    }
    if (storedState) {
      setUserState(storedState);
    }
    if (storedZip) {
      setUserZip(storedZip);
    }
  }, []);

  const togglePdfEditorModal = () => {
    setIsPdfEditorOpen(!isPdfEditorOpen);
  };

  const handleNewChat = () => {
    setMessage("");
    setNumber("");
    setBorder("");
    setMatchedBorder("");
    setLoadCompany("");
    setCustomsBroker("");
    setIsPdfEditorOpen(false);
    setCombinedPdfBlob(null);
    setToEmail("");
    setBrokerPhone("");
    setPrefix("");
    setPdfFiles([]);
    setFilteredBorders([]);
    dateString2 = "";
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmNewChat = () => {
    handleNewChat();
    closeModal();
  };

  const generatePdf = async () => {
    if (!number) {
      setMessage("Please provide a number for the barcode.");
      return;
    }
    setIsLoading(true);
    if (pdfFiles.length === 0) return;

    const mergedPdf = await PDFDocument.create();
    const customPage = mergedPdf.addPage();
    const { width, height } = customPage.getSize();
    const fontSize = 30;
    const text1 = `${prefix} ${number}`;
    const text2 = `Crossing at ${matchedBorder}`;

    const nowUTC = new Date();
    const currentDate = new Date(
      nowUTC.toLocaleString("en-US", { timeZone: "America/Vancouver" })
    );
    currentDate.setHours(currentDate.getHours() + 2);
    if (currentDate.getMinutes() >= 30) {
      currentDate.setMinutes(0);
      currentDate.setHours(currentDate.getHours() + 1);
    } else {
      currentDate.setMinutes(0);
    }

    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const dateString = currentDate.toLocaleString("en-US", options);
    const text3 = `On ${dateString}`;
    const centerX = width / 2;
    const centerY = height / 2;
    const font = await mergedPdf.embedFont(StandardFonts.Helvetica);

    const barcodeImage = await generateBarcodeImage(number, mergedPdf);
    if (!barcodeImage) {
      setMessage("Error generating barcode. Please try again.");
      return;
    }

    const barcodeHeight = 60;
    customPage.drawImage(barcodeImage, {
      x: centerX - barcodeImage.width / 2,
      y: centerY + barcodeHeight,
      width: barcodeImage.width,
      height: barcodeHeight,
    });

    const text1Width = font.widthOfTextAtSize(text1, fontSize);
    const text2Width = font.widthOfTextAtSize(text2, fontSize);
    const text3Width = font.widthOfTextAtSize(text3, fontSize);
    customPage.drawText(text1, {
      x: centerX - text1Width / 2,
      y: centerY,
      size: fontSize,
      font,
    });
    customPage.drawText(text2, {
      x: centerX - text2Width / 2,
      y: centerY - 40,
      size: fontSize,
      font,
    });
    customPage.drawText(text3, {
      x: centerX - text3Width / 2,
      y: centerY - 80,
      size: fontSize,
      font,
    });

    for (const file of pdfFiles) {
      const fileBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(fileBytes);
      const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      pages.forEach((page) => mergedPdf.addPage(page));
    }
    const mergedPdfBytes = await mergedPdf.save();
    const mergedPdfBlob = new Blob([mergedPdfBytes], {
      type: "application/pdf",
    });

    setCombinedPdfBlob(mergedPdfBlob);
    togglePdfEditorModal();
    setIsLoading(false);
  };

  const fetchPapsNumberForPrefix = async () => {
    try {
      let storedUserId = sessionStorage.getItem("storageUserId");
      let storedPapsStart = sessionStorage.getItem("storagePapsStart");
      const response = await fetch(
        `/api/fetch-new-number?userId=${encodeURIComponent(
          storedUserId
        )}&papsStart=${encodeURIComponent(storedPapsStart)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setNumber(data.number);
    } catch (error) {
      console.error("Failed to fetch number: ", error.message);
    }
  };

  const fetchParsNumberForPrefix = async () => {
    try {
      let storedUserId = sessionStorage.getItem("storageUserId");
      let storedParsStart = sessionStorage.getItem("storageParsStart");
      const response = await fetch(
        `/api/get-pars-new-number?userId=${encodeURIComponent(
          storedUserId
        )}&parsStart=${encodeURIComponent(storedParsStart)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setNumber(data.number);
    } catch (error) {
      console.error("Failed to fetch number: ", error.message);
    }
  };

  const pars_borders = [
    "Huntingdon, BC",
    "Pacific Highway, BC",
    "Osoyoos, BC",
    "Kingsgate, BC",
    "Coutts, AB",
    "North Portal, SK",
    "Regway, SK",
    "Emerson, MB",
    "Fort Erie, ON",
    "Windsor, ON",
    "Sarnia, ON",
    "Prescott, ON",
    "Lacolle, QC",
    "Stanstead, QC",
    "St. Stephen, NB",
    "Woodstock, NB",
  ];
  const paps_borders = [
    "Sumas, WA",
    "Blaine, WA",
    "Oroville, WA",
    "Eastport, ID",
    "Sweet Grass, MT",
    "Portal, ND",
    "Raymond, MT",
    "Pembina, ND",
    "Buffalo, NY",
    "Detroit, MI",
    "Port Huron, MI",
    "Ogdensburg, NY",
    "Champlain, NY",
    "Derby Line, VT",
    "Calais, ME",
    "Houlton, ME",
  ];

  const handleFileChange = (e) => {
    setPdfFiles(Array.from(e.target.files));
  };
  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const uploadToBlobStorage = async (pdfBlob, fileName) => {
    try {
      const base64Content = await blobToBase64(pdfBlob);
      const base64Data = base64Content.replace(/^data:.+;base64,/, "");

      const body = JSON.stringify({
        blobName: fileName,
        blobContent: base64Data,
      });

      const response = await fetch("/api/save-to-blob", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      const data = await response.json();
      if (response.ok) {
        console.log("PDF uploaded successfully: ", data.url);
        return data.url; // URL of the uploaded PDF in blob storage
      } else {
        throw new Error(data.error || "Failed to upload PDF");
      }
    } catch (error) {
      console.error("Error uploading PDF to blob storage: ", error);
      setMessage(`Error: ${error.message}`);
      return null;
    }
  };

  const generateBarcodeImage = async (data, mergedPdf) => {
    if (!data) {
      console.error("Barcode data is missing!");
      throw new Error("Barcode data is missing");
    }

    try {
      // Create a canvas element dynamically
      const canvas = document.createElement("canvas");
      // Generate barcode
      await bwipjs.toCanvas(canvas, {
        bcid: "code128", // Barcode type (Code 128)
        text: data, // Barcode text, make sure this is set correctly
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });

      // Get the image data from the canvas
      const barcodeDataUrl = canvas.toDataURL("image/png");
      const barcodeImageBytes = await fetch(barcodeDataUrl).then((res) =>
        res.arrayBuffer()
      );

      return await mergedPdf.embedPng(barcodeImageBytes);
    } catch (err) {
      console.error("Error generating barcode:", err);
      throw err;
    }
  };

  const updatePapsRow = async (blobUrl) => {
    try {
      const body = {
        Number: number,
        Border: matchedBorder,
        Broker: customsBroker,
        Email: toEmail,
        Phone: brokerPhone,
        Company: userCompanyName,
        LoadCompany: loadCompany,
        Status: "Active",
        PDF: blobUrl,
        UserId: storageUserId,
        Date: dateString2,
      };
      const response = await fetch("/api/update-paps", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(`Update successful: ${data.message}`);
      } else {
        throw new Error(data.error || "Failed to update the row");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      console.error("Failed to update Paps row:", error);
    }
  };

  const sendEmail = async () => {
    if (!combinedPdfBlob) {
      setMessage("Please generate a PDF first.");
      return;
    }

    setIsLoading(true);
    downloadPDF(combinedPdfBlob, `${prefix} ${number} ${matchedBorder}`);
    const blobUrl = await uploadToBlobStorage(
      combinedPdfBlob,
      `${number}-${matchedBorder}.pdf`
    );

    if (blobUrl) {
      console.log("Blob URL:", blobUrl);
    } else {
      console.log("Failed to upload PDF to blob storage");
    }
    const dateOnlyOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    const nowUTC = new Date();
    const currentDate = new Date(
      nowUTC.toLocaleString("en-US", { timeZone: "America/Vancouver" })
    );
    const dateOnlyString = currentDate.toLocaleString("en-US", dateOnlyOptions);
    dateString2 = dateOnlyString;
    sendNumberToBackend(number);
    sendEmailWithAttachment(
      toEmail,
      `${prefix} ${number} ${matchedBorder}`,
      combinedPdfBlob,
      "Thanks."
    );

    if (prefix === "PARS") updateParsRow(blobUrl);
    else if (prefix === "PAPS") updatePapsRow(blobUrl);

    setIsLoading(false);
  };

  const downloadPDF = (blob, fileName) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;

    // Programmatically click the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const updateParsRow = async (blobUrl) => {
    try {
      const body = {
        Number: number,
        Border: matchedBorder,
        Broker: customsBroker,
        Email: toEmail,
        Phone: brokerPhone,
        Company: userCompanyName,
        LoadCompany: loadCompany,
        Status: "Active",
        PDF: blobUrl,
        UserId: storageUserId,
        Date: dateString2,
      };
      const response = await fetch("/api/update-pars", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(`Update successful: ${data.message}`);
      } else {
        throw new Error(data.error || "Failed to update the row");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      console.error("Failed to update Pars row:", error);
    }
  };
  const updateBorderAndPrefix = (e) => {
    const inputBorder = e.target.value.trim();
    setBorder(inputBorder);
    const combinedBorders = [...pars_borders, ...paps_borders];
    const filtered = combinedBorders.filter((b) =>
      b.toLowerCase().includes(inputBorder.toLowerCase())
    );
    setFilteredBorders(filtered);

    const normalizedInputBorder = inputBorder.toLowerCase();
    const foundParsBorder = pars_borders.find((b) =>
      b.toLowerCase().includes(normalizedInputBorder)
    );
    const foundPapsBorder = paps_borders.find((b) =>
      b.toLowerCase().includes(normalizedInputBorder)
    );
    let newPrefix = "";
    if (foundParsBorder) {
      newPrefix = "PARS";
      setMatchedBorder(foundParsBorder);
    } else if (foundPapsBorder) {
      newPrefix = "PAPS";
      setMatchedBorder(foundPapsBorder);
    } else {
      setMatchedBorder("");
    }
    setPrefix(newPrefix);
    if (customsBroker && newPrefix) {
      fetchBrokerEmailz(customsBroker, newPrefix);
    }
  };

  function sendNumberToBackend(number) {
    const url = "/api/store-number";

    const data = {
      number: number,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
  }

  const fetchBrokerEmailz = async (brokerName, prefix) => {
    let toEmail = "";
    const brokerData = await fetchBrokerByName(brokerName);
    console.log("Broker data:", brokerData);
    if (!brokerData || brokerData.length === 0) {
      console.error("No broker data found.");
      setToEmail(toEmail);
      return;
    }
    const broker = brokerData[0];
    if (prefix === "PARS") {
      toEmail = broker.parsEmail || "";
    } else if (prefix === "PAPS") {
      toEmail = broker.papsEmail || "";
    }
    setBrokerPhone(broker.brokerPhone || "");
    console.log("BrokerPhone:", broker.brokerPhone);
    setToEmail(toEmail);
  };

  const sendEmailWithAttachment = async (
    toEmail,
    subject,
    attachment,
    body
  ) => {
    const emails = toEmail.split(",");
    const primaryEmail = emails.shift();
    const ccEmails = emails.join(",");
    const replyTo = userCustomsEmail;
    const formData = new FormData();
    formData.append("firstName", userFirstName);
    formData.append("lastName", userLastName);
    formData.append("companyName", userCompanyName);
    formData.append("phone", userPhone);
    formData.append("toEmail", primaryEmail);
    formData.append("storageUserId", storageUserId);
    formData.append("ccEmails", ccEmails);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("attachment", attachment);
    formData.append("replyTo", replyTo);
    formData.append("number", number);
    formData.append("address", userAddress);
    formData.append("city", userCity);
    formData.append("state", userState);
    formData.append("zip", userZip);
    try {
      const response = await fetch("/api/send-email-with-attachment", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      setMessage("An error occurred while sending the email.");
      console.error("Error:", error);
    }
  };

  const fetchBrokerByName = async (brokerName) => {
    try {
      const endpoint = `/api/fetch-broker`; // Adjusted to match the route of the Azure Function
      const response = await fetch(
        `${endpoint}?name=${encodeURIComponent(brokerName)}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      const data = await response.json();
      return data.result; // Adjusted to match the response structure from the Azure Function
    } catch (error) {
      console.error("Error fetching broker:", error);
      return null;
    }
  };

  const Dropdown = ({ items, onSelect }) => {
    return (
      <div className="dropdown">
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              onClick={() => onSelect(item)}
              className="dropdown-item"
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const updateCustomsBroker = (e) => {
    const inputBroker = e.target.value;
    setCustomsBroker(inputBroker);
    if (inputBroker && prefix) {
      fetchBrokerEmailz(inputBroker, prefix);
    }
  };

  useEffect(() => {
    // Get the textarea element
    const textarea = document.getElementById("toEmail");

    if (!textarea) return; // Exit if textarea does not exist

    // Function to adjust textarea height
    const autoResize = () => {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = textarea.scrollHeight + "px"; // Set height based on content
    };

    autoResize();
    textarea.addEventListener("input", autoResize);

    return () => textarea.removeEventListener("input", autoResize);
  }, [toEmail]); // Trigger effect when toEmail changes

  const selectBorder = (border) => {
    setBorder(border);
    setFilteredBorders([]); // Hide dropdown after selection
    const event = { target: { value: border } };
    updateBorderAndPrefix(event);
  };

  return (
    <div className="customs-container">
      <SideNavBar handleNewChat={openModal} />
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Customs"
      />
      <div className="custom-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div className="custom-body">
          <input
            type="file"
            id="pdfUpload"
            multiple
            accept="application/pdf"
            className="file-input"
            onChange={handleFileChange}
          />
          <div className="border-input-container">
            <input
              type="text"
              placeholder="BORDER :"
              value={border}
              onChange={updateBorderAndPrefix}
              onBlur={() => setTimeout(() => setFilteredBorders([]), 200)}
              onFocus={() =>
                updateBorderAndPrefix({ target: { value: border } })
              }
              className="border-input"
            />
            {filteredBorders.length > 0 && (
              <Dropdown items={filteredBorders} onSelect={selectBorder} />
            )}
          </div>

          <input
            type="text"
            placeholder="BROKER :"
            value={customsBroker}
            onChange={updateCustomsBroker}
            className="border-input"
          />
          <input
            type="text"
            placeholder="COMPANY NAME :"
            value={loadCompany}
            onChange={(e) => setLoadCompany(e.target.value)}
            className="border-input"
          />
          <input
            type="text"
            placeholder="NUMBER :"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="border-input"
          />
          <label htmlFor="toEmail" className="input-label label-inline">
            BROKER'S EMAIL :
          </label>
          <TextField
            type="text"
            id="toEmail"
            value={toEmail}
            onChange={(e) => setToEmail(e.target.value)}
            variant="outlined"
            className="text-field-standard"
            InputProps={{
              style: { color: "white" },
            }}
            InputLabelProps={{ className: "input-label" }}
            fullWidth
          />
          <Button
            className="generate-button"
            onClick={generatePdf}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "GENERATE PDF"}
          </Button>
          <Button
            className="send-button"
            onClick={sendEmail}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "SEND EMAIL"}
          </Button>
          <Modal
            isOpen={isPdfEditorOpen}
            onRequestClose={togglePdfEditorModal}
            contentLabel="PDF Editor"
            style={{ content: { inset: "3%", overflow: "auto" } }}
            ariaHideApp={false}
          >
            <PdfEditor
              combinedPdfBlob={combinedPdfBlob}
              onSave={(updatedBlob) => {
                setCombinedPdfBlob(updatedBlob);
                togglePdfEditorModal();
              }}
              showSideNavBar={false}
              paddingLeft="12%"
              paddingRight="5%"
            />
          </Modal>
          <p className="message">{message}</p>
        </div>
      </div>
    </div>
  );
}

export default App;
