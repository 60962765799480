import React, { useRef, useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import * as pdfjs from "pdfjs-dist";
import { saveAs } from "file-saver";
import ConfirmationModal from "./ConfirmationModal";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import "./PdfEditor.css";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  Grid,
  Typography,
  Slider,
  TextField,
} from "@mui/material";
import SideNavBar from "./SideNavBar";
import UndoIcon from "@mui/icons-material/Undo";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import BrushIcon from "@mui/icons-material/Brush";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete"; // Import DeleteIcon

pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.js";

function PdfEditor({
  combinedPdfBlob,
  onSave,
  showSideNavBar = true,
  paddingLeft = "23%",
  paddingRight = "10%",
}) {
  const canvasRef = useRef(null);
  const [text, setText] = useState("");
  const [pdfDoc, setPdfDoc] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [drawingColor, setDrawingColor] = useState("#FF0000");
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [pdfBuffer, setPdfBuffer] = useState(null);
  const [modifiedPdfBytes, setModifiedPdfBytes] = useState(null);
  const [drawMode, setDrawMode] = useState("draw");
  const [canvasData, setCanvasData] = useState({});
  const [history, setHistory] = useState({});
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const loadPdf = async () => {
      if (combinedPdfBlob) {
        const buffer = await combinedPdfBlob.arrayBuffer();
        const pdfjsDoc = pdfjs.getDocument({ data: buffer });
        const pdfLibDoc = await PDFDocument.load(buffer);
        const pdf = await pdfjsDoc.promise;
        setTotalPages(pdf.numPages);
        setPdfDoc(pdf);
        setPdfBuffer(pdfLibDoc);
        setCurrentPage(1);
        setCanvasData({});
        setHistory({}); // Reset history when new PDF is loaded
      }
    };

    if (combinedPdfBlob) {
      loadPdf();
    }
  }, [combinedPdfBlob]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const confirmNewChat = () => {
    setText("");
    setPdfDoc(null);
    setCurrentPage(1);
    setTotalPages(0);
    setMouseDown(false);
    setMousePosition({ x: 0, y: 0 });
    setDrawingColor("#FF0000");
    setStrokeWidth(2);
    setPdfBuffer(null);
    setModifiedPdfBytes(null);
    setDrawMode("draw");
    setCanvasData({});
    setHistory({});
    console.log("New chat confirmed.");
    closeModal();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const buffer = await file.arrayBuffer();
      const pdfjsDoc = pdfjs.getDocument({ data: buffer });
      const pdfLibDoc = await PDFDocument.load(buffer);
      const pdf = await pdfjsDoc.promise;
      setTotalPages(pdf.numPages);
      setPdfDoc(pdf);
      setPdfBuffer(pdfLibDoc);
      setCurrentPage(1);
      setCanvasData({});
      setHistory({}); // Reset history when new PDF is loaded
    }
  };

  const getMousePosition = (canvas, event) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;
    return { x, y };
  };

  const startDrawing = (e) => {
    const position = getMousePosition(canvasRef.current, e);
    setMousePosition(position);
    setMouseDown(true);
    if (drawMode === "text") {
      drawText(position.x, position.y);
      saveCanvasData();
      setMouseDown(false);
    }
  };

  const draw = (e) => {
    if (!mouseDown) return;
    const newPosition = getMousePosition(canvasRef.current, e);
    const ctx = canvasRef.current.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(mousePosition.x, mousePosition.y);
    ctx.lineTo(newPosition.x, newPosition.y);
    ctx.strokeStyle = drawingColor;
    ctx.lineWidth = strokeWidth;
    ctx.stroke();
    setMousePosition(newPosition);
  };

  const drawText = (x, y) => {
    const ctx = canvasRef.current.getContext("2d");
    const fontStyle = `${isItalic ? "italic " : ""}${isBold ? "bold " : ""}`;
    ctx.font = `${fontStyle}${strokeWidth * 10}px Helvetica`;
    ctx.fillStyle = drawingColor;
    text.split("\n").forEach((line, i) => {
      ctx.fillText(line, x, y + i * strokeWidth * 10);
    });
  };

  const handleMouseMove = (e) => {
    if (drawMode === "draw") {
      draw(e);
    }
  };

  const handleMouseUp = () => {
    setMouseDown(false);
    if (drawMode === "draw") {
      saveCanvasData();
    }
  };

  const saveCanvasData = () => {
    const dataUrl = canvasRef.current.toDataURL("image/png");
    setCanvasData((prevData) => ({
      ...prevData,
      [currentPage]: dataUrl,
    }));

    // Update history for undo
    setHistory((prevHistory) => ({
      ...prevHistory,
      [currentPage]: [...(prevHistory[currentPage] || []), dataUrl], // Append new state
    }));
  };

  const modifyPdf = async () => {
    if (!pdfBuffer || !canvasRef.current) return;
    const modifiedPdfDoc = await PDFDocument.create();
    for (let i = 1; i <= pdfBuffer.getPageCount(); i++) {
      const [copiedPage] = await modifiedPdfDoc.copyPages(pdfBuffer, [i - 1]);
      modifiedPdfDoc.addPage(copiedPage);
      if (canvasData[i]) {
        const canvasImage = await modifiedPdfDoc.embedPng(canvasData[i]);
        const { width, height } = copiedPage.getSize();
        copiedPage.drawImage(canvasImage, { x: 0, y: 0, width, height });
      }
    }
    const pdfBytes = await modifiedPdfDoc.save();
    const updatedBlob = new Blob([pdfBytes], { type: "application/pdf" });
    if (onSave) {
      onSave(updatedBlob);
    } else {
      setModifiedPdfBytes(updatedBlob);
    }
  };

  const downloadModifiedPdf = () => {
    if (!modifiedPdfBytes) return;
    saveAs(
      new Blob([modifiedPdfBytes], { type: "application/pdf" }),
      "modified-document.pdf"
    );
  };

  const undoLastAction = () => {
    if (!history[currentPage] || history[currentPage].length === 0) return;

    const newHistory = history[currentPage].slice(0, -1);

    setCanvasData((prevData) => ({
      ...prevData,
      [currentPage]:
        newHistory.length > 0 ? newHistory[newHistory.length - 1] : null,
    }));

    setHistory((prevHistory) => ({
      ...prevHistory,
      [currentPage]: newHistory,
    }));
  };

  const handleDeletePage = async () => {
    if (!pdfBuffer || !pdfDoc) return;

    // Prevent deleting the last page
    if (totalPages <= 1) {
      alert("Cannot delete the last page of the PDF.");
      return;
    }

    // Remove the current page from pdfBuffer
    pdfBuffer.removePage(currentPage - 1);

    // Update canvasData
    setCanvasData((prevData) => {
      const newData = {};
      Object.keys(prevData).forEach((key) => {
        const pageNum = parseInt(key, 10);
        if (pageNum < currentPage) {
          newData[pageNum] = prevData[pageNum];
        } else if (pageNum > currentPage) {
          newData[pageNum - 1] = prevData[pageNum];
        }
        // Skip the currentPage, which we're deleting
      });
      return newData;
    });

    // Update history
    setHistory((prevHistory) => {
      const newHistory = {};
      Object.keys(prevHistory).forEach((key) => {
        const pageNum = parseInt(key, 10);
        if (pageNum < currentPage) {
          newHistory[pageNum] = prevHistory[pageNum];
        } else if (pageNum > currentPage) {
          newHistory[pageNum - 1] = prevHistory[pageNum];
        }
        // Skip the currentPage, which we're deleting
      });
      return newHistory;
    });

    // Save the modified pdfBuffer to bytes
    const pdfBytes = await pdfBuffer.save();

    // Create a new pdfjs pdfDoc from pdfBytes
    const pdfjsDoc = pdfjs.getDocument({ data: pdfBytes });

    // Wait for the pdfjsDoc promise to resolve
    const pdf = await pdfjsDoc.promise;

    // Update totalPages
    const newTotalPages = pdf.numPages;
    setTotalPages(newTotalPages);

    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }

    // Update pdfDoc
    setPdfDoc(pdf);

    // Update currentPage if necessary
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }

    // Update pdfBuffer
    const pdfLibDoc = await PDFDocument.load(pdfBytes);
    setPdfBuffer(pdfLibDoc);
  };

  useEffect(() => {
    if (!pdfDoc) return;

    // Ensure currentPage is within valid range
    if (currentPage > pdfDoc.numPages) {
      setCurrentPage(pdfDoc.numPages);
      return; // Wait for currentPage to update
    }

    if (currentPage < 1) {
      setCurrentPage(1);
      return; // Wait for currentPage to update
    }

    let renderTask = null;

    const renderPdfPage = async (pdf, pageNumber) => {
      if (renderTask) {
        renderTask.cancel();
      }

      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 2.5 });
      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      renderTask = page.render(renderContext);
      try {
        await renderTask.promise;
      } catch (error) {
        console.error("Error rendering PDF page:", error);
      } finally {
        renderTask = null;
      }
    };

    const currentCanvasData = canvasData[currentPage];

    if (!currentCanvasData) {
      renderPdfPage(pdfDoc, currentPage);
    } else {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext("2d");
      const img = new Image();
      img.src = currentCanvasData;
      img.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas before rendering
        context.drawImage(img, 0, 0);
      };
    }

    return () => {
      if (renderTask) {
        renderTask.cancel();
      }
    };
  }, [currentPage, pdfDoc, canvasData]);

  return (
    <div className="App">
      {showSideNavBar && (
        <SideNavBar handleNewChat={openModal} /> // Pass handleNewChat to SideNavBar
      )}
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Start a New Chat"
      />
      <div className="pdfEditorWindow">
        <Toolbar style={{ minHeight: "87px" }} />
        <div
          className="pdfEditorBody"
          style={{ paddingLeft: paddingLeft, paddingRight: paddingRight }}
        >
          <AppBar position="static" color="primary">
            {!pdfDoc && (
              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                color="inherit"
                sx={{
                  backgroundColor: "black",
                  border: "1px solid #3c94fc",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                    borderColor: "black",
                    border: "1px solid #3c94fc",
                  },
                }}
              >
                Upload PDF
                <input
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                  accept="application/pdf"
                />
              </Button>
            )}

            {pdfDoc && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexGrow: 1,
                    backgroundColor: "black",
                  }}
                >
                  <IconButton
                    color="inherit"
                    onClick={() => setCurrentPage((v) => Math.max(v - 1, 1))}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ mx: 2 }}>
                    Page {currentPage} of {totalPages}
                  </Typography>
                  <IconButton
                    color="inherit"
                    onClick={() =>
                      setCurrentPage((v) => Math.min(v + 1, totalPages))
                    }
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    backgroundColor: "black",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<UndoIcon />}
                    onClick={undoLastAction}
                    sx={{
                      backgroundColor: "black",
                      border: "1px solid #3c94fc",
                    }}
                  >
                    Undo
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<SaveIcon />}
                    onClick={modifyPdf}
                    sx={{
                      backgroundColor: "black",
                      border: "1px solid #3c94fc",
                    }}
                  >
                    Save Changes
                  </Button>
                  {!onSave && (
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DownloadIcon />}
                      onClick={downloadModifiedPdf}
                      sx={{
                        backgroundColor: "black",
                        border: "1px solid #3c94fc",
                      }}
                    >
                      Download
                    </Button>
                  )}
                  {totalPages > 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={handleDeletePage}
                      sx={{
                        backgroundColor: "black",
                        border: "1px solid #3c94fc",
                      }}
                    >
                      Delete Page
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    height: "20px",
                    backgroundColor: "black",
                  }}
                ></Box>
                <Box
                  className="toolPanel"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    backgroundColor: "black",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                      flexDirection: "row",
                      gap: 2,
                      backgroundColor: "black",
                    }}
                  >
                    <Button
                      variant={drawMode === "text" ? "contained" : "outlined"}
                      startIcon={<TextFieldsIcon />}
                      onClick={() => setDrawMode("text")}
                      fullWidth
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        border: "1px solid #3c94fc",
                      }}
                    >
                      Text
                    </Button>
                    <Button
                      variant={drawMode === "draw" ? "contained" : "outlined"}
                      startIcon={<BrushIcon />}
                      onClick={() => setDrawMode("draw")}
                      fullWidth
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        border: "1px solid #3c94fc",
                      }}
                    >
                      Draw
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexGrow: 1,
                      flexDirection: "row",
                      gap: 2,
                    }}
                  >
                    <Typography gutterBottom>Color</Typography>
                    <input
                      type="color"
                      value={drawingColor}
                      onChange={(e) => setDrawingColor(e.target.value)}
                      style={{ width: "50px", height: "50px" }}
                      className="colorPicker"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                      flexDirection: "row",
                      gap: 2,
                    }}
                  >
                    <Typography gutterBottom>Stroke Width</Typography>
                    <Slider
                      value={strokeWidth}
                      onChange={(e, val) => setStrokeWidth(val)}
                      aria-labelledby="stroke-width-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={10}
                    />
                  </Box>

                  {drawMode === "text" && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexGrow: 1,
                          flexDirection: "row",
                          gap: 2,
                        }}
                      >
                        <TextField
                          placeholder="Enter Text"
                          rows={4}
                          variant="outlined"
                          value={text}
                          sx={{
                            backgroundColor: "black",
                            border: "1px solid #3c94fc",
                          }}
                          onChange={(e) => setText(e.target.value)}
                          fullWidth
                          InputLabelProps={{
                            style: { color: "white" },
                          }}
                          InputProps={{
                            style: { color: "white", backgroundColor: "#333" },
                          }}
                          className="textInput"
                        />
                        <ToggleButtonGroup
                          aria-label="text formatting"
                          sx={{
                            marginTop: 2,
                          }}
                          exclusive={false}
                        >
                          <ToggleButton
                            value="bold"
                            selected={isBold}
                            onChange={() => setIsBold(!isBold)}
                            aria-label="bold"
                            sx={{
                              color: "white",
                              borderColor: "#3c94fc",
                              "&.Mui-selected": {
                                backgroundColor: "#3c94fc",
                                color: "black",
                              },
                            }}
                          >
                            <FormatBoldIcon />
                          </ToggleButton>
                          <ToggleButton
                            value="italic"
                            selected={isItalic}
                            onChange={() => setIsItalic(!isItalic)}
                            aria-label="italic"
                            sx={{
                              color: "white",
                              borderColor: "#3c94fc",
                              "&.Mui-selected": {
                                backgroundColor: "#3c94fc",
                                color: "black",
                              },
                            }}
                          >
                            <FormatItalicIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
          </AppBar>
          {pdfDoc && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <canvas
                  ref={canvasRef}
                  onMouseDown={startDrawing}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                  className="pdfCanvas"
                ></canvas>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

export default PdfEditor;
