import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Toolbar } from "@mui/material";
import Spinner from "./Spinner";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  Typography,
  Pagination,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Broker.css";

function PapsManagement() {
  const [papsInfo, setPapsInfo] = useState([]);
  const [newPapsNumber, setNewPapsNumber] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userId, setUserId] = useState("");
  const itemsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [papsStart, setPapsStart] = useState("");
  const navigate = useNavigate();

  const handleEditPaps = (papsData) => {
    sessionStorage.setItem("papsData", papsData);
    console.log("PAPS Data:", papsData);
    navigate(`/edit`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const storedUserId = sessionStorage.getItem("storageUserId");
      const storedPapsStart = sessionStorage.getItem("storagePapsStart");

      if (storedUserId) setUserId(storedUserId);
      if (storedPapsStart) setPapsStart(storedPapsStart);

      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-paps?userId=${encodeURIComponent(storedUserId)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setPapsInfo(data.result);
      } catch (error) {
        console.error("Failed to fetch PAPS information:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchPapsInfo = async () => {
    setIsLoading(true);
    try {
      let storedUserId = sessionStorage.getItem("storageUserId");
      const response = await fetch(
        `/api/fetch-paps?userId=${encodeURIComponent(storedUserId)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setPapsInfo(data.result);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleAddPapsNumber = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const numbers = newPapsNumber.split(",").map((num) => num.trim());
    console.log("Adding PAPS numbers:", numbers);
    const endpoint = `/api/insert-paps`;

    for (const number of numbers) {
      const data = { number, userId }; // Include userId in the data object
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        await response.json();
      } catch (error) {
        console.error("Error inserting PAPS number:", error);
      }
    }
    setNewPapsNumber("");
    await fetchPapsInfo();
    setIsLoading(false);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("pdf", selectedFile);
      formData.append("paps_start", papsStart);
      const response = await fetch("/api/extract-paps-numbers", {
        method: "POST",
        body: formData,
      });
      if (!response.ok) throw new Error(`HTTP status ${response.status}`);
      alert("PAPS numbers extracted from PDF and added to the database.");
      await fetchPapsInfo();
    } catch (error) {
      console.error("Error uploading PDF to extract PAPS:", error.message);
    } finally {
      setSelectedFile(null);
      closeModal();
    }
  };

  const handleDeletePaps = async (number) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete PAPS number: ${number}?`
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-paps`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ number }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("PAPS number deleted successfully!");
      setPapsInfo(papsInfo.filter((item) => item.Number !== number));
    } catch (error) {
      console.error("Failed to delete PAPS number:", error);
      alert(`Error deleting PAPS number: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredItems = papsInfo.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.Number?.toLowerCase().includes(searchLower) ||
      item.Border?.toLowerCase().includes(searchLower) ||
      item.Broker?.toLowerCase().includes(searchLower) ||
      item.Email?.toLowerCase().includes(searchLower) ||
      item.Phone?.toLowerCase().includes(searchLower) ||
      item.Status?.toLowerCase().includes(searchLower) ||
      item.LoadCompany?.toLowerCase().includes(searchLower) ||
      item.Date?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
      secondary: {
        main: "#f50057",
      },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            backgroundColor: "#000000",
            padding: "16px 0",
          },
          ul: {
            justifyContent: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000000",
            "&.Mui-selected": {
              backgroundColor: "#3c94fc",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#111111",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                PAPS Management
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by any column"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
                <Button
                  onClick={openModal}
                  variant="contained"
                  color="primary"
                  class="add-button"
                  sx={{ m: 2 }}
                >
                  Add PAPS Numbers
                </Button>
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="paps table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Number</TableCell>
                          <TableCell>Border</TableCell>
                          <TableCell>Broker</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Company</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {currentItems.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.Number}</TableCell>
                            <TableCell>{item.Border}</TableCell>
                            <TableCell>{item.Broker}</TableCell>
                            <TableCell>{item.Email}</TableCell>
                            <TableCell>{item.Phone}</TableCell>
                            <TableCell>{item.Status}</TableCell>
                            <TableCell>{item.LoadCompany}</TableCell>
                            <TableCell>{item.Date}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleEditPaps(item.Number)}
                                sx={{ color: "#fff" }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeletePaps(item.Number)}
                                sx={{ color: "#fff" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={Math.ceil(filteredItems.length / itemsPerPage)}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      color="primary"
                      sx={{
                        py: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                      showFirstButton
                      showLastButton
                    />
                  </TableContainer>
                  <Dialog open={isModalOpen} onClose={closeModal} fullWidth>
                    <DialogTitle>Add PAPS Numbers</DialogTitle>
                    <DialogContent>
                      <Typography gutterBottom>
                        Enter PAPS numbers (comma-separated)
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={newPapsNumber}
                        onChange={(e) => setNewPapsNumber(e.target.value)}
                        inputProps={{ style: { color: "#fff" } }}
                        InputLabelProps={{ style: { color: "#fff" } }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#555",
                            },
                            "&:hover fieldset": {
                              borderColor: "#777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                      />
                      <DialogActions>
                        <Button onClick={handleAddPapsNumber} color="primary">
                          Submit PAPS Numbers
                        </Button>
                      </DialogActions>
                      <Typography gutterBottom>
                        Upload PDF to Extract Numbers
                      </Typography>
                      <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        sx={{ my: 2 }}
                      >
                        Upload PDFs
                        <input
                          type="file"
                          id="pdfUpload"
                          multiple
                          accept="application/pdf"
                          hidden
                          onChange={handleFileChange}
                        />
                      </Button>
                      <DialogActions>
                        <Button
                          onClick={handleFileUpload}
                          color="primary"
                          disabled={!selectedFile}
                        >
                          Upload PDF
                        </Button>
                      </DialogActions>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeModal} color="secondary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default PapsManagement;
