// LoadQuote.js
import React, { useState, useEffect, useRef } from "react";
import SideNavBar from "./SideNavBar";
import MapMessage from "./MapMessage";
import LoadingIndicator from "./LoadingIndicator";
import ConfirmationModal from "./ConfirmationModal";
import QuoteDetails from "./QuoteDetails"; // Ensure this component exists
import "./Chat.css";
import { Toolbar } from "@mui/material";

function LoadQuote() {
  const MAX_MESSAGES = 30;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [chatHistory, setChatHistory] = useState(() => {
    const storedData = localStorage.getItem("load_quote_chat");
    return storedData ? JSON.parse(storedData) : [];
  });

  const [input, setInput] = useState("");
  const chatBodyRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    // Initialize session ID
    let sessionId = sessionStorage.getItem("session_id");
    if (!sessionId) {
      sessionId = generateSessionId();
      sessionStorage.setItem("session_id", sessionId);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("load_quote_chat", JSON.stringify(chatHistory));
  }, [chatHistory]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory, isLoading]);

  const addSystemMessage = (text) => {
    const newMessage = {
      id: generateUniqueId(),
      text,
      isUser: false,
    };
    setChatHistory((prevHistory) =>
      [...prevHistory, newMessage].slice(-MAX_MESSAGES)
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmNewChat = async () => {
    await handleRequestCompleted(false);
    setChatHistory([]);
    addSystemMessage("How can I assist you with Load Quote?");
    closeModal();
  };

  const handleRequestCompleted = async (displayMessage = true) => {
    const textToSend = "This part is completed";
    setIsLoading(true);

    if (displayMessage) {
      const userMessage = {
        id: generateUniqueId(),
        text: textToSend,
        isUser: true,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, userMessage].slice(-MAX_MESSAGES)
      );
    }

    try {
      const response = await callAzureFunction(textToSend);
      const answer = response.message;

      const aiMessage = {
        id: generateUniqueId(),
        text: answer,
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, aiMessage].slice(-MAX_MESSAGES)
      );
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, errorMessage].slice(-MAX_MESSAGES)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = async () => {
    const textToSend = input.trim();
    if (textToSend === "") return;

    const userMessage = {
      id: generateUniqueId(),
      text: textToSend,
      isUser: true,
    };

    setChatHistory((prevHistory) =>
      [...prevHistory, userMessage].slice(-MAX_MESSAGES)
    );

    setInput("");
    setIsLoading(true);

    // **Added Code Starts Here**
    try {
      const response = await fetch("/api/find-pickup-delivery", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: textToSend,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch locations from the backend.");
      }

      const data = await response.json();
      const origin = data.message.pickup_location;
      const destination = data.message.delivery_location;

      let googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/directions?key=AIzaSyArBf33-aVC4NwUZEQJALQZPgmZbbC1_wM&origin=${encodeURIComponent(
        origin
      )}&destination=${encodeURIComponent(destination)}`;

      const mapMessage = {
        id: generateUniqueId(),
        mapUrl: googleMapsEmbedUrl,
        isUser: false,
        isMap: true,
      };

      setChatHistory((prevHistory) =>
        [...prevHistory, mapMessage].slice(-MAX_MESSAGES)
      );
    } catch (err) {
      console.error("Error fetching locations and generating map:", err);
      // Optionally, display an error message to the user
    }
    // **Added Code Ends Here**

    try {
      // Call API to process the user's message
      const response = await callAzureFunction(textToSend);
      const data = response;

      // **Parse the backend response**
      const parsedDetails = parseBackendResponse(data.message); // Added this line

      const aiMessage = {
        id: generateUniqueId(),
        details: parsedDetails, // Store parsed details
        isUser: false,
        isLoadQuote: true, // Custom flag to identify load quote messages
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, aiMessage].slice(-MAX_MESSAGES)
      );
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, errorMessage].slice(-MAX_MESSAGES)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const callAzureFunction = async (inputText) => {
    const apiUrl = "/api/load-quote";

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: inputText,
        session_id: sessionStorage.getItem("session_id"),
      }),
    });

    if (!response.ok) {
      throw new Error("API request failed");
    }

    return await response.json();
  };

  const parseBackendResponse = (responseText) => {
    const details = {};
    // Split by double newline to get each line
    const lines = responseText.split(/\n\s*\n/);
    lines.forEach((line) => {
      // Split by the first colon to separate key and value
      const [key, ...valueParts] = line.split(":");
      if (key && valueParts.length > 0) {
        const value = valueParts.join(":").trim();
        // Handle multiple values separated by commas
        details[key.trim()] = value;
      }
    });
    return details;
  };

  const adjustInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${Math.min(
        inputRef.current.scrollHeight,
        150
      )}px`;
    }
  };

  useEffect(() => {
    adjustInputHeight();
  }, [input]);

  const generateUniqueId = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  const generateSessionId = () => {
    return crypto.randomUUID();
  };

  return (
    <div className="chat-container">
      <SideNavBar handleNewChat={openModal} />
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Load Quote"
      />
      <div className="chat-window">
        <Toolbar sx={{ minHeight: "84px" }} />
        <div className="chat-body" ref={chatBodyRef}>
          {chatHistory.map((message) => (
            <div
              key={message.id}
              className={`chat-message ${
                message.isUser ? "user-message" : "system-message"
              }`}
            >
              {message.isMap ? (
                <MapMessage mapUrl={message.mapUrl} />
              ) : message.isLoadQuote ? (
                <QuoteDetails details={message.details} />
              ) : (
                message.text
              )}
            </div>
          ))}
          {isLoading && <LoadingIndicator />}
        </div>
        <div className="chat-footer">
          {/* <button className="file-upload-btn" title="Upload Files">
            📌
          </button> */}
          <textarea
            ref={inputRef}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              adjustInputHeight();
            }}
            onKeyDown={(e) => e.key === "Enter" && !e.shiftKey && handleSend()}
            placeholder="Type a message..."
            className="input-field"
          />
          <button
            className="send-btn"
            title="Send Message"
            onClick={handleSend}
            aria-label="Send Message"
            disabled={isLoading}
          >
            ➤
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoadQuote;
