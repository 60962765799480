import React from "react";
import { useNavigate } from "react-router-dom";
import { Toolbar } from "@mui/material";
import "./Chat.css";

function MainPage() {
  const navigate = useNavigate();

  const handleOptionSelect = (option) => {
    switch (option) {
      case "📄 Customs":
        navigate("/customs");
        break;
      // case "💰 Invoicing":
      //   navigate("/invoicing");
      //   break;
      case "🚚 Load Quote":
        navigate("/load-quote");
        break;
      // case "📝 Document Generation":
      //   navigate("/document-generation");
      //   break;
      case "✉️ Email Generation":
        navigate("/email-generation");
        break;
      case "📦 Orders":
        navigate("/orders");
        break;
      case "📑 Edit PDF":
        navigate("/edit-pdf");
        break;
      default:
        break;
    }
  };

  const options = [
    "📄 Customs",
    // "💰 Invoicing",
    "🚚 Load Quote",
    "✉️ Email Generation",
    // "📝 Document Generation",
    "📦 Orders",
    "📑 Edit PDF",
  ];

  return (
    <div className="app">
      <div className="welcome-screen">
        <Toolbar style={{ minHeight: "83px" }} />
        <h1 className="welcome-heading">What can I help with?</h1>
        <div className="options-container">
          {options.map((option) => (
            <button
              key={option}
              className="welcome-option-btn"
              onClick={() => handleOptionSelect(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MainPage;
