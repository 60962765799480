// QuoteDetails.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

function QuoteDetails({ details }) {
  // Define which keys should be grouped together
  const dimensionKeys = ["Length in ft", "Width in ft", "Height in ft"];

  // Extract dimension values if they exist
  const dimensions = dimensionKeys
    .map((key) => {
      const value = details[key];
      return value ? `${value} ft (${key.split(" ")[0]})` : null;
    })
    .filter(Boolean)
    .join(" x ");

  // Create a new details object without individual dimension keys
  const filteredDetails = Object.entries(details).reduce(
    (acc, [key, value]) => {
      if (!dimensionKeys.includes(key)) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: "20px", backgroundColor: "black" }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        Load Quote Details
      </Typography>
      <Table>
        <TableBody>
          {dimensions && (
            <TableRow key="Dimensions">
              <TableCell style={{ fontWeight: "bold", color: "white" }}>
                Dimensions
              </TableCell>
              <TableCell style={{ color: "white" }}>{dimensions}</TableCell>
            </TableRow>
          )}
          {/* Render the remaining details */}
          {Object.entries(filteredDetails).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell style={{ fontWeight: "bold", color: "white" }}>
                {key
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
              </TableCell>
              <TableCell style={{ color: "white" }}>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default QuoteDetails;
